export const SOFTWARE_KEYS = {
	c36_operation_unit: 'C36 Operation Unit',
	c36_powerpack_control_box: 'C36 Powerpack Control Box',
	hydro_hammer_control_box: 'Hydro Hammer Control Box',
	powerpack_control_box: 'Powerpack Control Box',
	ACQ: 'ACQ',
	Bios: 'Bios',
	CB: 'CB',
	CH66: 'CH66',
	HSI: 'HSI',
	'PH66-1': 'PH66-1',
	'PH66-2': 'PH66-2',
	'PH66-3': 'PH66-3',
	'PH66-4': 'PH66-4',
	POU: 'POU',
	PSI: 'PSI',
	SRV: 'SRV',
};

export default SOFTWARE_KEYS;
