import { useSettings } from '.';
/**
 * React custom hook.
 * @param {*} parseFn Parses the iqip_analytics env variable.
 * @returns projectId & link
 */
export const useIqipAnalyticsSettings = () => {
  const { projectId, link } = useSettings(({ IQIP_ANALYTICS }) => {
    return JSON.parse(IQIP_ANALYTICS || '{}');
  });
  return { projectId, link };
};
