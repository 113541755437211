// Imports => React
import React, { useState, useCallback, useMemo } from 'react';

// Imports => Constants
import { TYPES } from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcIsUndefined,
  AcIsNull,
  AcIsArray,
  AcIsObject,
  AcIsNumeric,
  AcIsEmptyString,
  AcIsString,
  AcIsEmail,
  AcSanitize,
  AcIsPhoneNumber,
} from '@utils';

export const useFormActions = ({
  fields = {},
  setFields = () => {},
  errors = {},
  setErrors = () => {},
}) => {
  const hasErrors = useMemo(() => {
    if (!AcIsSet(errors) || !AcIsObject(errors)) return false;
    let result = false;

    for (let key in errors) {
      if (!AcIsNull(errors[key])) {
        result = true;
        break;
      }
    }

    return result;
  }, [fields, errors]);

  const getChildData = (obj, key) => obj[key];

  const cloneDeepLimited = (obj) => JSON.parse(JSON.stringify(obj));

  const setFormValues = (data, name, value) => {
    return new Promise((resolve) => {
      const dataClone = cloneDeepLimited(data);
      const keys = name.split('.'); // 'a.b.c' => ['a', 'b', 'c'] | 'a' => ['a']
      const nestedSegments = keys.slice(0, -1); // ['a', 'b'] | []
      const [finalSegment] = keys.slice(-1); // 'c' | 'a'
      const finalData = nestedSegments.reduce(getChildData, dataClone);
      finalData[finalSegment] = value;
      resolve(dataClone);
      return dataClone;
    });
  };

  const handleInputChange = (event, name, value, type, checked) => {
    return new Promise((resolve) => {
      let _value = (event && event.target && event.target.value) || value;

      if (type === TYPES.CHECKBOX) {
        const arr = fields[name];
        if (arr && AcIsArray(arr)) {
          if (checked && arr.indexOf(_value) === -1) arr.push(_value);
          if (!checked && arr.indexOf(_value) > -1)
            arr.splice(arr.indexOf(_value), 1);
          _value = arr;
        }
      } else if (type === TYPES.RADIO) {
        _value = AcSanitize(_value);
      } else if (type === TYPES.BOOLEAN) {
        _value = checked;
      } else if (type === TYPES.ON_OFF) {
        _value = checked ? 1 : 0;
      } else if (AcIsString(_value) && type !== TYPES.TEXTAREA) {
        _value = AcSanitize(_value);
      }

      if (setFields) {
        setFormValues(fields, name, _value).then((values) => {
          setFields(values);
        });
      }

      resolve();
    });
  };

  const handleInputValidation = (name, value, required, type) => {
    let result = errors;

    switch (type) {
      case TYPES.EMAIL:
        if ((!AcIsSet(value) || AcIsEmptyString(value)) && required) {
          result[name] = 'This is a required field';
        } else if (!AcIsEmptyString(value) && !AcIsEmail(value)) {
          result[name] = 'This is not a valid emailaddress';
        } else {
          result[name] = null;
        }
        break;

      case TYPES.PASSWORD:
        if ((!AcIsSet(value) || AcIsEmptyString(value)) && required) {
          result[name] = 'This is a required field';
        } else {
          result[name] = null;
        }
        break;

      case TYPES.PHONE:
        if (value && !AcIsPhoneNumber(value)) {
          result[name] = 'This is not a valid phone number';
        }
        if (!value) {
          result[name] = required ? 'This is a required field' : null;
        }
        break;

      case TYPES.TEXT:
      case TYPES.BOOLEAN:
      case TYPES.RADIO:
        if ((!AcIsSet(value) || AcIsEmptyString(value)) && required) {
          result[name] = 'This is a required field';
        } else {
          result[name] = null;
        }
        break;

      case TYPES.CHECKBOX:
        if (!AcIsSet(fields[name]) && required) {
          result[name] = 'This is a required field';
        } else if (
          AcIsArray(fields[name]) &&
          fields[name].length === 0 &&
          required
        ) {
          result[name] = 'Choose at least 1 option';
        } else {
          result[name] = null;
        }
        break;

      default:
    }

    if (setErrors) setErrors(result);

    return result[name];
  };

  return {
    hasErrors,
    handleInputChange,
    handleInputValidation,
  };
};
