export const ROLES = {
  CHUCK_NORRIS: 'super-admin',
  ADMIN: 'admin',
  SERVICE_ENGINEER: 'service-engineer',
  RENTAL_COORDINATOR: 'rental-coordinator',
  COMPANY_ADMIN: 'company-admin',
  OPERATOR: 'operator',
  USER: 'user',
  CDE: 'client-desk-engineer',
};
