/* eslint react-app/import/no-webpack-loader-syntax: off */
import CONTROL_UNIT_SCHEMATIC from '-!svg-react-loader!@assets/images/control-unit-schematic-1x.svg';
import CONTROL_UNIT_66_SCHEMATIC from '-!svg-react-loader!@assets/images/control-unit-66-schematic-1x.svg';
import LOADER from '-!svg-react-loader!@assets/images/loader-1x.svg';
import LOGO from '-!svg-react-loader!@assets/images/logo-1x.svg';
import WHATSNEW from '-!svg-react-loader!@assets/images/whats-new-1x.svg';

export const VISUALS = {
  CONTROL_UNIT_SCHEMATIC,
  CONTROL_UNIT_66_SCHEMATIC,
  LOADER,
  LOGO,
  WHATSNEW,
};

export default {
  CONTROL_UNIT_SCHEMATIC,
  CONTROL_UNIT_66_SCHEMATIC,
  LOADER,
  LOGO,
  WHATSNEW,
};
