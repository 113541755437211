import { useState, useEffect } from 'react';

/**
 * React custom hook.
 * @param {*} parseFn Parses the env variables before state will be set.
 * @returns Parsed or 'raw' env variables
 */
export const useSettings = (parseFn) => {
  const [settings, setSettings] = useState({});
  useEffect(() => {
    if (process.env) {
      if (parseFn && typeof parseFn === 'function') {
        setSettings(parseFn(process.env));
      } else {
        setSettings(process.env);
      }
    }
  }, []);
  return settings;
};
