// Imports => Utilities
import { AcIsSet } from '@utils';

let _searchDelay = null;

export const useOverviewActions = (store, methods = null) => {
	if (!AcIsSet(store)) {
		console.warn(
			`[hook] UseOverviewActions => store is not set. Please provide a valid MobX Store instance`
		);
		return;
	}

	const handleSort = (field, get = true) => {
		store.setOrderBy(field);
		if (get) {
			if (methods && methods.list) methods.list();
			else store.list();
		}
	};

	const handlePagination = (number, get = true) => {
		store.setPageNumber(number);
		if (get) {
			if (methods && methods.list) methods.list();
			else store.list();
		}
	};

	const handleSearch = (event, name, value, get = true) => {
		if (_searchDelay) clearTimeout(_searchDelay);

		store.setQuery(value);
		store.setPageNumber(1);

		if (get) {
			_searchDelay = setTimeout(() => {
				if (methods && methods.list) methods.list();
				else store.list();
			}, 300);
		}
	};

	return {
		handleSort,
		handlePagination,
		handleSearch,
	};
};
