import { KEYS } from './keys.constants';
import { TITLES } from './titles.constants';

export const CONTROL_UNIT = [];

export const HAMMER = {
	[KEYS.TIMING]: {
		key: KEYS.TIMING,
		title: TITLES.PARAMS.TIMING,
		list: ['T0', 'T1Max', 'T2', 'T3', 'T4', 'T5Max', 'T6', 'T7', 'T8'],
	},
	[KEYS.VALVE]: {
		key: KEYS.VALVE,
		title: TITLES.PARAMS.VALVE,
	},
	[KEYS.OTHER]: {
		key: KEYS.OTHER,
		title: TITLES.PARAMS.OTHER,
		list: [
			'ADHigh',
			'ALow',
			'BLevel',
			'CappEm',
			'CapPressureRange',
			'CappZero',
			'CustomEHammer',
			'DAb',
			'DPiston',
			'DRod',
			'EAdd',
			'EHammer',
			'HSIType',
			'MRam',
			'QHammer',
		],
	},
	[KEYS.OPTIONS]: {
		key: KEYS.OPTIONS,
		title: TITLES.PARAMS.OPTIONS,
		list: ['BackPresOffByDefault', 'Extract', 'BoostMode'],
	},
};

//

export const POWERPACK = {
	[KEYS.CAP]: {
		key: KEYS.CAP,
		title: TITLES.PARAMS.CAP,
		list: ['CappEm', 'CappZero'],
	},
	[KEYS.DEB]: {
		key: KEYS.DEB,
		title: TITLES.PARAMS.DEB,
		list: ['DebEm', 'DebZero'],
	},
	[KEYS.FLOW]: {
		key: KEYS.FLOW,
		title: TITLES.PARAMS.FLOW,
		list: ['QMax', 'NEngine', 'QMin'],
	},
	[KEYS.PRESSURE_RANGE]: {
		key: KEYS.PRESSURE_RANGE,
		title: TITLES.PARAMS.PRESSURE_RANGE,
		list: ['PressEm', 'PresZero'],
	},
	[KEYS.POCLAIN]: {
		key: KEYS.POCLAIN,
		title: TITLES.PARAMS.POCLAIN,
		list: [
			'PoclainSpeedStart',
			'PoclainSpeedMax',
			'PoclainPulsesPerRev',
			'PoclainVolumePerRev',
			'PoclainA0F0',
			'PoclainA1F0',
			'PoclainA0F1',
			'PoclainA1F1',
		],
	},
};

export const PARAMETERS = {
	CONTROL_UNIT,
	HAMMER,
	POWERPACK,
};

export default PARAMETERS;
