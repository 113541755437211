// Imports => Dependencies
import React, { useContext } from 'react';

// Imports => StoreContext
import { StoreContext } from '@stores';

// Imports => Constants
import { PERMISSIONS } from '@constants';

// Imports => Utilities
import { AcIsSet, AcIsNull, AcIsArray } from '@utils';

export const usePermissions = () => {
	const store = useContext(StoreContext);
	const { auth } = store;

	if (!AcIsSet(auth)) {
		console.warn(
			`[hook] UsePermissions => store is not set. Please provide a valid MobX Store instance`
		);
		return;
	}

	const is = (role, collection) => {
		let { current_roles: arr } = auth;

		if (AcIsSet(collection)) arr = collection;
		if (!AcIsSet(arr)) return false;

		return arr.indexOf(role) > -1;
	};

	const canAssignRoles = () => {
		let count = 0;

		if (can(PERMISSIONS.USER.CREATE_COMPANY_ADMIN)) count = count + 1;
		if (can(PERMISSIONS.USER.CREATE_OPERATOR)) count = count + 1;
		if (can(PERMISSIONS.USER.CREATE_RENTAL_COORDINATOR)) count = count + 1;
		if (can(PERMISSIONS.USER.CREATE_SERVICE_ENGINEER)) count = count + 1;

		return count > 1;
	};

	const can = (action) => {
		if (AcIsNull(action)) return true;

		const { current_permissions } = auth;
		if (!AcIsSet(current_permissions)) return false;

		if (AcIsArray(action)) {
			const len = action.length;
			let n = 0;
			let result = false;

			for (n; n < len; n++) {
				const line = action[n];

				if (current_permissions[line]) {
					result = true;
					break;
				}
			}

			return result;
		} else {
			return current_permissions[action];
		}
	};

	const cannot = (permission) => {
		return !can(permission);
	};

	return {
		is,
		can,
		cannot,
		canAssignRoles,
	};
};
