export const PERMISSIONS = {
  ADMIN: {
    CREATE: 'ADMIN.CREATE',
    READ: 'ADMIN.READ',
    UPDATE: 'ADMIN.UPDATE',
    DELETE: 'ADMIN.DELETE',
  },
  ADVANCED_PASSWORD: {
    READ: 'ADVANCED_PASSWORD.READ',
    UPDATE: 'ADVANCED_PASSWORD.UPDATE',
  },
  ALERT: {
    READ: 'ALERT.SHOW',
    UPDATE: 'ALERT.UPDATE',
  },
  BASIC_PASSWORD: {
    READ: 'BASIC_PASSWORD.READ',
    UPDATE: 'BASIC_PASSWORD.UPDATE',
  },
  COMPANY: {
    CREATE: 'COMPANY.CREATE',
    READ: 'COMPANY.READ',
    UPDATE: 'COMPANY.UPDATE',
    DELETE: 'COMPANY.DELETE',
  },
  CONFIGURATION: {
    CREATE: 'CONFIGURATION.CREATE',
    READ: 'CONFIGURATION.READ',
    READ_ALL: 'CONFIGURATION.READ.ALL',
    READ_BY_COMPANY: 'CONFIGURATION.READ.BY_COMPANY',
    UPDATE: 'CONFIGURATION.UPDATE',
    UPDATE_COMPANY: 'CONFIGURATION.UPDATE_COMPANY',
    UPDATE_OWNER: 'CONFIGURATION.UPDATE_OWNER',
    DELETE: 'CONFIGURATION.DELETE',
  },
  CONTRACT: {
    CREATE: 'CONTRACT.CREATE',
    READ: 'CONTRACT.READ',
    UPDATE: 'CONTRACT.UPDATE',
    DELETE: 'CONTRACT.DELETE',
  },
  EQUIPMENT: {
    CONTROL_UNITS: 'EQUIPMENT.CONTROL_UNITS',
    CREATE: 'EQUIPMENT.CREATE',
    HAMMERS: 'EQUIPMENT.HAMMERS',
    OPTIONS_READ: 'EQUIPMENT.OPTIONS_READ',
    OPTIONS_UPDATE: 'EQUIPMENT.OPTIONS_UPDATE',
    POWERPACKS: 'EQUIPMENT.POWERPACKS',
    READ: 'EQUIPMENT.READ',
    READ_ALL: 'EQUIPMENT.READ.ALL',
    READ_COMPANY: 'EQUIPMENT.READ.COMPANY',
    READ_CONFIGURATIONS: 'EQUIPMENT.READ.CONFIGURATIONS',
    READ_CONTRACTS: 'EQUIPMENT.READ.CONTRACTS',
    READ_PARAMETERS: 'EQUIPMENT.READ.PARAMETERS',
    UPDATE: 'EQUIPMENT.UPDATE',
    UPDATE_OBJECT_NO: 'EQUIPMENT.UPDATE.OBJECT_NO',
    UPDATE_PARAMETERS: 'EQUIPMENT.UPDATE.PARAMETERS',
    UPDATE_TYPE: 'EQUIPMENT.UPDATE.TYPE',
    DELETE: 'EQUIPMENT.DELETE',
    REPORT_READ: 'EQUIPMENT.REPORT.READ',
  },
  EQUIPMENT_OWNER: {
    READ: 'EQUIPMENT_OWNER.READ',
    UPDATE: 'EQUIPMENT_OWNER.UPDATE',
    DELETE: 'EQUIPMENT_OWNER.DELETE',
  },
  EQUIPMENT_TYPE: {
    CREATE: 'EQUIPMENT_TYPE.CREATE',
    READ: 'EQUIPMENT_TYPE.READ',
    UPDATE: 'EQUIPMENT_TYPE.UPDATE',
    DELETE: 'EQUIPMENT_TYPE.DELETE',
  },
  JEAN_LUTZ_USERNAME: {
    CREATE: 'JEAN_LUTZ_USERNAME.CREATE',
    READ: 'JEAN_LUTZ_USERNAME.READ',
    UPDATE: 'JEAN_LUTZ_USERNAME.UPDATE',
    DELETE: 'JEAN_LUTZ_USERNAME.DELETE',
  },
  MY_COMPANY: {
    CREATE: 'MY_COMPANY.CREATE',
    READ: 'MY_COMPANY.READ',
    UPDATE: 'MY_COMPANY.UPDATE',
    DELETE: 'MY_COMPANY.DELETE',
  },
  NAVIGATION: {
    ALERTS: {
      OPERATIONAL: 'NAVIGATION.ALERT',
    },
    COMPANY: 'NAVIGATION.COMPANY',
    MY_COMPANY: 'NAVIGATION.MY_COMPANY',
    CONFIGURATION: 'NAVIGATION.CONFIGURATION',
    CONTRACT: 'NAVIGATION.CONTRACT',
    DASHBOARD_CONTROL_UNIT: 'NAVIGATION.DASHBOARD.CONTROL_UNIT',
    DASHBOARD_EQUIPMENT: 'NAVIGATION.DASHBOARD.EQUIPMENT',
    DASHBOARD_PROJECT_DETAIL: 'NAVIGATION.DASHBOARD.PROJECT-DETAIL',
    DASHBOARD_PROJECT_OVERVIEW: 'NAVIGATION.DASHBOARD.PROJECT-OVERVIEW',
    EQUIPMENT: 'NAVIGATION.EQUIPMENT',
    EQUIPMENT_CONTROL_UNITS: 'NAVIGATION.EQUIPMENT.CONTROL_UNITS',
    EQUIPMENT_HAMMERS: 'NAVIGATION.EQUIPMENT.HAMMERS',
    EQUIPMENT_POWER_PACKS: 'NAVIGATION.EQUIPMENT.POWER_PACKS',
    EQUIPMENT_TYPE: 'NAVIGATION.EQUIPMENT_TYPE',
    EQUIPMENT_TYPE_CONTROL_UNITS: 'NAVIGATION.EQUIPMENT_TYPE.CONTROL_UNITS',
    EQUIPMENT_TYPE_HAMMERS: 'NAVIGATION.EQUIPMENT_TYPE.HAMMERS',
    EQUIPMENT_TYPE_POWER_PACKS: 'NAVIGATION.EQUIPMENT_TYPE.POWER_PACKS',
    USER: 'NAVIGATION.USER',
  },

  PARAMETERS: {
    CREATE: 'PARAMETERS.CREATE',
    READ: 'PARAMETERS.READ',
    UPDATE: 'PARAMETERS.UPDATE',
  },
  PILE: {
    CREATE: 'PILE.STORE',
    READ: 'PILE.SHOW',
    UPDATE: 'PILE.UPDATE',
    DELETE: 'PILE.DELETE',
    DELETE: 'PILE.DELETE',
    'EXPORT.CONTROL-UNIT': 'PILE.EXPORT.CONTROL-UNIT',
    'EXPORT.XLS': 'PILE.EXPORT.XLS',
    SEND: 'PILE.SEND',
    SHOW: 'PILE.SHOW',
    'SHOW.TRANSFERS': 'PILE.SHOW.TRANSFERS',
    STORE: 'PILE.STORE',
    'STORE.IMPORT': 'PILE.STORE.IMPORT',
    UPDATE: 'PILE.UPDATE',
  },
  PROJECT: {
    CREATE: 'PROJECT.CREATE',
    CREATE_FOR_COMPANIES: 'PROJECT.CREATE.FOR_COMPANIES',
    CREATE_COMPANY_NAME: 'PROJECT.CREATE.COMPANY_NAME',
    CREATE_COMPANY_UID: 'PROJECT.CREATE.COMPANY_UID',
    READ: 'PROJECT.READ',
    READ_BY_COMPANY: 'PROJECT.READ.BY_COMPANY',
    READ_COMPANY_INFO: 'PROJECT.READ.COMPANY_UID',
    READ_CONFIGURATIONS: 'PROJECT.READ.CONFIGURATIONS',
    READ_RENTED: 'PROJECT.READ.RENTED',
    READ_USERS: 'PROJECT.READ.USERS',
    UPDATE: 'PROJECT.UPDATE',
    UPDATE_COMPANY_INFO: 'PROJECT.UPDATE.COMPANY_INFO',
    UPDATE_CONFIGURATIONS: 'PROJECT.UPDATE.CONFIGURATIONS',
    UPDATE_USERS: 'PROJECT.UPDATE.USERS',
    DELETE: 'PROJECT.DELETE',
    READ_REPORT: 'PROJECT.REPORT.READ',
    DELETE_REPORT: 'PROJECT.REPORT.DELETE',
    RESTORE_REPORT: 'PROJECT.REPORT.RESTORE',
  },
  REPORT: {
    CREATE: 'REPORT.CREATE',
    READ: 'REPORT.READ',
    UPDATE: 'REPORT.UPDATE',
    DELETE: 'REPORT.DELETE',
  },
  TRANSFERS: {
    SHOW_TRANSFERS: 'PILE.SHOW.TRANSFERS',
    SHOW_ALL: 'PILE.SHOW.ALL',
    SHOW_ALL_TRANSFERS: 'PILE.SHOW.TRANSFERS.ALL',
  },
  USER: {
    CREATE: 'USER.CREATE',
    CREATE_COMPANY_ADMIN: `USER.CREATE.COMPANY_ADMIN`,
    CREATE_OPERATOR: `USER.CREATE.OPERATOR`,
    CREATE_RENTAL_COORDINATOR: `USER.CREATE.RENTAL_COORDINATOR`,
    CREATE_SERVICE_ENGINEER: `USER.CREATE.SERVICE_ENGINEER`,
    READ: 'USER.READ',
    READ_COMPANY_ADMIN: 'USER.READ.COMPANY_ADMIN',
    READ_OPERATOR_BY_COMPANY: 'USER.READ.OPERATOR.BY_COMPANY',
    UPDATE: 'USER.UPDATE',
    UPDATE_COMPANY: 'USER.UPDATE_COMPANY',
    UPDATE_PHONE: 'USER.UPDATE_PHONE',
    DELETE: 'USER.DELETE',
  },
};

export default PERMISSIONS;
