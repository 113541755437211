import { useEffect, useState, useCallback } from 'react';

/**
 * Custom hook for using a delay (debouncing) on a state change.
 * @param {*} value The value which needs to be 'debounced'.
 * @param {*} delay The amount of ms.
 */
export const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState();
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
};

/**
 * Custom hook for managing the user's filter input.
 * @param {*} filter The filter function or method to call whenever the filter value changes.
 */
export const useDebounceInput = (filter) => {
  const [value, setValue] = useState();
  const debouncedValue = useDebounce(value);


  const onInputChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  useEffect(() => {
    filter(debouncedValue);
  }, [debouncedValue]);

  return [onInputChange, setValue, value, debouncedValue];
};
