export const FILE_TYPES = {
	CSV: 'text/csv',
	PDF: 'application/pdf',
	RAW: 'application/vnd.rar',
	XLS: 'application/vnd.ms-excel',
	XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	XML: 'application/xml',
	ZIP: 'application/zip',
	LST: 'text/plain',
};

export default FILE_TYPES;
